/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon16 from "../images/fav-16.png";
import favicon32 from "../images/fav-32.png";
import favicon64 from "../images/fav-64.png";

function SEO({ description, lang, meta, title }) {
  const { site, heroBirds } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        heroBirds: file(relativePath: { eq: "RaraAvisLab.png" }) {
          childImageSharp {
            fluid(maxWidth: 693) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={site.siteMetadata.title}
      titleTemplate={`${site.siteMetadata.title}`}
      description={metaDescription}
      meta={[
        {
          name: `robots`,
          content: meta.robots || `index, follow`
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `Transforma tu idea en realidad con Rara Avis Lab`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `https://raraavislab.es`
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.siteUrl}${heroBirds.childImageSharp.fluid.src}`
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: '@RaraAvisLab',
        },
        {
          name: `twitter:site`,
          content: '@RaraAvisLab',
        },
        {
          name: `twitter:title`,
          content: 'Transforma tu idea en realidad con Rara Avis Lab',
        },
        {
          name: `twitter:description`,
          content: 'Lanza tu proyecto tecnoloǵico con Rara Avis Lab, empresa especializada en Software e Internet, Web, Apps y Herramientas Off-line',
        },
        {
          name: 'twitter:image',
          content: `${site.siteMetadata.siteUrl}${heroBirds.childImageSharp.fluid.src}`
        }
      ].concat(meta)}
      link={[
        { rel: 'canonical', href: "https://raraavislab.es/" },
        { rel: "icon", type: "image/png", sizes: "16x16", href: `${favicon16}` },
        { rel: "icon", type: "image/png", sizes: "32x32", href: `${favicon32}` },
        { rel: 'shortcut icon', type: 'image/png', href: `${favicon64}` },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `es`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
