/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from "react"
import PropTypes from "prop-types"
import Navbar from "./navbar"
import { StaticQuery, graphql } from "gatsby";
import GDPRSettingsPanel from "./gdpr-panel";
import '../styles/style.scss';

class Layout extends Component {

  showCookiesModal() {
    this.refs.gdpr.showCookiesModal();
  }
  
  render() {
    return (
      <>
        <StaticQuery
          query={graphql`
            query {
              logo1: file(relativePath: { eq: "logorara.png" }) {
                childImageSharp {
                  fixed(width: 80, height: 86) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
                }
              }
            }`
          }
          render={data => (
              <Navbar logo={data.logo1.childImageSharp.fixed}></Navbar>
            )
          }
        />
        <div className="nav-divider">
  
        </div>
        {this.props.children}
        <GDPRSettingsPanel ref="gdpr"></GDPRSettingsPanel>
  
        <footer className="footer hero is-primary is-bold">
            <div className="container is-fluid has-text-centered">
              <div className="columns is-centered">
                  <div className="column is-12">
                    <a className="button is-medium round-button" href="https://www.linkedin.com/company/rara-avis-lab/" target="_blank" rel="noindex,nofollow">
                      <span className="icon">
                        <i className="linkedin-icon"></i>
                      </span>
                    </a>
                    {/* <a className="button is-medium round-button">
                      <span className="icon">
                        <i className="twitter-icon"></i>
                      </span>
                    </a> */}
                  </div>
              </div>
              <div className="columns is-centered">
                  <div className="column is-12">
                    <b>&copy; RARA AVIS LAB</b>
                  </div>
              </div>
              <div className="columns">
                  <div className="column is-12 has-text-justified	">
                    <a href="/aviso-legal/" target="_blank" rel="noindex,nofollow" className="link has-text-primary-light">Aviso legal</a>
                    <span> | </span>
                    <a href="/privacidad-cookies/" target="_blank" rel="noindex,nofollow" className="link has-text-primary-light">Política de privacidad y cookies</a>
                    <span> | </span>
                    <button onClick={() => this.showCookiesModal()} className="link has-text-primary-light">Cookies</button>
                  </div>
              </div>
            </div>
        </footer>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
