import React, { Component } from "react";
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

export default class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isNavExpanded: false,
    };
  }

  handleToggle(e) {
    e.preventDefault();
    this.setState({
        isNavExpanded: !this.state.isNavExpanded
    });
  }

  render() {
    const { isNavExpanded } = this.state;
    return (
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
            <h1 className="is-sr-only">Rara Avis Lab Diseño y Ejecución del ciclo completo de Diseño, Desarrollo y Optimización de Software en Internet.</h1>
            <Link className="navbar-item navbar-logo" to="/">
                <Img fixed={this.props.logo} loading="eager" alt="Logo Rara Avis Lab" />
            </Link>

            <a role="button" className={`navbar-burger burger ${isNavExpanded ? "is-active" : ""}`} aria-label="menu" aria-expanded="false"
               data-target="raranavbar" onClick={e => this.handleToggle(e)}>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div id="raranavbar" className={`navbar-menu ${isNavExpanded ? "is-active" : ""}`}>
            <div className="navbar-start">
            </div>

            <div className="navbar-end">
                <Link to="/#servicios" className="navbar-item has-text-primary">
                  Servicios
                </Link>
                <a href="/#proceso" className="navbar-item has-text-primary">
                  Proceso
                </a>
                <a href="/#experiencias" className="navbar-item has-text-primary">
                  Experiencias
                </a>
                <a href="/#clientes" className="navbar-item has-text-primary">
                  Clientes
                </a>
                <div className="navbar-item">
                    <a href="/#lanza-tu-proyecto" className="button is-primary">CONTACTAR</a>
                </div>
            </div>
        </div>
      </nav>
    )
  }
}