import React, { Component } from "react";
import ReactDOM from 'react-dom';
import {GDPRSettings,GDPRConfigBuilder,cookiesTool} from 'react-gdpr'

export default class GDPRSettingsPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allowRender: false,
            render: 0,
            basicEnabled: true
        }
        this.allowAll = this.allowAll.bind(this);
        this.showCookiesModal = this.showCookiesModal.bind(this);
    }

    componentDidMount() {
        // this.setState({allowRender: !cookiesTool.isActive('basic-enabled')})
        this.setState({basicEnabled: cookiesTool.isActive('basic-enabled')});
    }

    static getDerivedStateFromProps(props, state) {
        if (state.allowRender) {
            document.documentElement.style.overflowY = 'hidden';
        }
        return state;
    }

    allowAll() {
        cookiesTool.setActive('basic-enabled', true);
        cookiesTool.setActive('google-analytics', true);
        cookiesTool.setActive('facebook-pixel', true);
        this.setState({basicEnabled: cookiesTool.isActive('basic-enabled')});

        this.setState(prevState => {
            return {
                render: prevState.render + 1
            }
        });
    }

    showCookiesModal() {
        this.setState({allowRender: true});
    }
    handleToggle = (id, value) => {
        cookiesTool.setActive(id, value);
        this.setState(prevState => {
            return {
                render: prevState.render + 1
            }
        });
    }

    handleValidate = () => {
        cookiesTool.setActive('basic-enabled', true);
        this.setState({basicEnabled: cookiesTool.isActive('basic-enabled')});
        this.setState({allowRender: false});
        document.documentElement.style.overflowY = 'scroll';
    }

    render() {
        // if (!this.state.allowRender) return (
        //     <button className="cookies-button" onClick={() => this.showCookiesModal()}>
        //         <span className="icon-cookie"></span>
        //     </button>
        // );

        let configBuilder = new GDPRConfigBuilder();
        configBuilder.setLocale(
            'Configuración de cookies',
            `Aquí puedes modificar la configuración de las cookies según la ley RGPD.\n
            Siempre podrás abrir la configuración desde el footer.`,
            'Seguir Navegando',
            'Activado',
            'Desactivado',
            'Necesario'
        ).addSetting(
            'basic-enabled',
            'Esenciales',
            'Cookies esenciales para el funcionamiento de la página, no son compartidas y solo se usan para ofrecer una mejor experiencia, como no mostrate todo el rato este configurador.',
            true,
            false
        ).addSetting(
            'google-analytics',
            'Analítica de Google',
            'Permite que Google analize el uso de nuestra página web.',
            cookiesTool.isActive('google-analytics'),
            true
        ).addSetting(
            'facebook-pixel',
            'Analítica de Facebook',
            'Permite que Facebook obtenga métricas de conversión de nuestra página web.',
            cookiesTool.isActive('facebook-pixel'),
            true
        );
        
        let config = configBuilder.getConfig();

        if (!this.state.basicEnabled && !this.state.allowRender) {
            return (
                <>
                    <div className="cookieBanner">
                        <div className="container">
                            <div className="columns is-multiline is-vcentered">
                                <div className="column is-full-touch has-text-centered-touch">
                                    <span className="title is-4">Uso de cookies</span>
                                    <p>Este sitio utiliza cookies para ofrecer una mejor experiencia y para estudiar como los usuarios utilizan el sitio con el objetivo de poder mejorar.</p>
                                    <p>Puede aceptar todas las cookies o escoger las que desea aceptar.</p>
                                </div>
                                <div className="column is-full-touch has-text-centered">
                                    <button className="button is-primary" onClick={this.allowAll}>Aceptar todas</button>
                                    <button className="button is-primary" onClick={this.showCookiesModal}>Configurar cookies</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }

        return !this.state.allowRender ? null : (<><div className="gdpr-settings-panel">
        {true && <GDPRSettings onValidate={this.handleValidate} onToggle={this.handleToggle} {...config} />}
    </div></>);
        // return (
            
        // )
    }
}